export const BeachHut = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M20 9.22221L20.3861 9.52248C20.8221 9.86154 21.4503 9.78301 21.7894 9.34706C22.1285 8.91112 22.0499 8.28284 21.614 7.94377L13.8419 1.89879C12.7585 1.0562 11.2416 1.0562 10.1582 1.89879L2.38611 7.94377C1.95016 8.28284 1.87162 8.91112 2.21069 9.34706C2.54976 9.78301 3.17804 9.86154 3.61399 9.52248L4.00005 9.22221V20.7331H3.00005C2.44776 20.7331 2.00005 21.1808 2.00005 21.7331C2.00005 22.2854 2.44776 22.7331 3.00005 22.7331H21C21.5523 22.7331 22 22.2854 22 21.7331C22 21.1808 21.5523 20.7331 21 20.7331H20V9.22221ZM11.3861 3.47749C11.7472 3.19663 12.2529 3.19663 12.614 3.47749L14.2284 4.73312H9.77173L11.3861 3.47749ZM7.2003 6.73312L5.99798 7.66826C5.99935 7.6897 6.00005 7.71133 6.00005 7.73312V8.73312H8.76394C9.31326 8.11937 10.1115 7.73312 11 7.73312H13C13.8885 7.73312 14.6868 8.11937 15.2362 8.73312H18V7.73312C18 7.71133 18.0007 7.6897 18.0021 7.66826L16.7998 6.73312H7.2003ZM16 10.7331V12.7331H18V10.7331H16ZM6.00005 10.7331H8.00005V12.7331H6.00005V10.7331ZM16 14.7331V16.7331H18V14.7331H16ZM6.00005 14.7331H8.00005V16.7331H6.00005V14.7331ZM16 18.7331V20.7331H18V18.7331H16ZM6.00005 18.7331H8.00005V20.7331H6.00005V18.7331ZM11 9.73312C10.4478 9.73312 10 10.1808 10 10.7331V20.7331H14V10.7331C14 10.1808 13.5523 9.73312 13 9.73312H11Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
