import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { Button } from '@spiaggeit/spit-ui'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from '../../assets/icons/ArrowRight'
import { Cart } from '../../assets/icons/Cart'
import { Close } from '../../assets/icons/Close'
import { ExclamationMark } from '../../assets/icons/ExclamationMark'
import { ItemQuantityController } from '../../components/ItemQuantityController/ItemQuantityController'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import { mapSelectors, mapSlice } from '../../store/mapSlice'
import { mockedSetupItems } from '../../utils/constants'

export const ElementDetail = () => {
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(mapSlice.actions.selectElement(null))
  }

  const { t } = useTranslation()

  const totalPriceLabel = useMemo(() => {
    if (!period) return
    const startDate = DateTime.fromISO(period.start)
    const endDate = DateTime.fromISO(period.end)

    const dayDifference = endDate.diff(startDate, 'days').days + 1

    if (dayDifference > 1) {
      return t('map.elementDetail.multipleDaysTotalPrice', {
        days: String(dayDifference),
      })
    } else if (dayDifference === 1 && bookingPeriod === BookingPeriod.MORNING) {
      return t('map.elementDetail.morningTotalPrice')
    } else if (
      dayDifference === 1 &&
      bookingPeriod === BookingPeriod.AFTERNOON
    ) {
      return t('map.elementDetail.afternoonTotalPrice')
    }
    return t('map.elementDetail.singleDayTotalPrice')
  }, [period, bookingPeriod])

  return (
    <div className="fixed inset-0 z-50 bg-black/60 data-[state=open]:animate-overlayShow">
      <div className="absolute bottom-0 left-0 right-0 flex h-fit grow flex-col gap-3 bg-white lg:left-auto lg:top-0 lg:h-screen lg:min-w-96">
        <div className="flex items-center justify-between gap-2 border-b border-solid border-gray-200 p-4">
          <div>
            <h3 className="text-lg font-bold text-primary">
              {selectedElement?.name}
            </h3>
            <span className="flex cursor-pointer items-center gap-1 text-sm text-secondary underline">
              {t('map.elementDetail.info')}
              <ExclamationMark />
            </span>
          </div>
          <button
            aria-label={t('common.closeImperativeVerb')}
            onClick={handleClose}
          >
            <Close />
          </button>
        </div>
        <div className="flex flex-col gap-2 p-4">
          <h3 className="text-lg font-bold text-primary">
            {t('map.elementDetail.setup')}
          </h3>
          {mockedSetupItems.map((item) => {
            return (
              <ItemQuantityController
                {...item}
                initialQuantity={0}
                key={item.id}
              />
            )
          })}
        </div>
        <div className="border-t border-solid border-gray-200 p-4 lg:mt-auto">
          <div className="flex items-center justify-between gap-1 text-sm text-secondary">
            {/* TODO: add price calculation logic */}
            <span>{selectedElement?.name}</span>
            <span>00,00€</span>
          </div>
          <div className="flex items-center justify-between gap-1 text-sm text-secondary">
            <span className="underline">
              {t('map.elementDetail.serviceCost')}
            </span>
            <span>0,00€</span>
          </div>
          <div className="flex items-center justify-between gap-1 text-lg font-bold text-primary">
            <span>{totalPriceLabel}</span>
            <span>00,00€</span>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  cartSlice.actions.add({ id: Number(selectedElement?.id) })
                )
                handleClose()
              }}
              variant="outline"
            >
              {t('map.elementDetail.addToCart')}
              <Cart />
            </Button>
            <Button>
              {t('map.elementDetail.finishBooking')}
              <ArrowRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
