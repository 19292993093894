export const MAP_CELL_SIZE = 39

export const mockedSetupItems = [
  {
    available: { global: 60, group: 3, ticket: 6 },
    hasLimit: true,
    icon: 'aperol.svg',
    id: 111,
    minimumQuantity: 0,
    name: 'Aperol spritz',
    onAdd: () => {
      return
    },
    onRemove: () => {
      return
    },
    price: 6,
  },
  {
    available: { global: 60, group: 3, ticket: 6 },
    hasLimit: true,
    icon: 'champagne.svg',
    id: 222,
    minimumQuantity: 0,
    name: 'Champagne',
    onAdd: () => {
      return
    },
    onRemove: () => {
      return
    },
    price: 34,
  },
  {
    available: { global: 60, group: 6, ticket: 6 },
    hasLimit: true,
    icon: 'hamburger.svg',
    id: 333,
    minimumQuantity: 0,
    name: 'Hamburger Menu',
    onAdd: () => {
      return
    },
    onRemove: () => {
      return
    },
    price: 12,
  },
]
