import { Button, cn } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Minus } from '../../assets/icons/Minus'
import { Plus } from '../../assets/icons/Plus'
import { priceFormatter } from '../../utils/price'

interface Props {
  available: {
    global: number
    group: number
    ticket: number
  }
  hasLimit: boolean
  icon: string
  name: string
  minimumQuantity: number
  price: number
  initialQuantity?: number
  onAdd: VoidFunction
  onRemove: VoidFunction
}

export const ItemQuantityController = (props: Props) => {
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(props.initialQuantity || 0)
  const availableQuantity = props.hasLimit
    ? Math.min(
      props.available.global,
      props.available.group,
      props.available.ticket - quantity
    )
    : Infinity
  const isSoldOut = props.hasLimit && props.available.ticket === 0

  const handleRemove = () => {
    if (quantity > props.minimumQuantity) {
      setQuantity(quantity - 1)
      props.onRemove()
    }
  }

  const handleAdd = () => {
    if (availableQuantity > 0) {
      setQuantity(quantity + 1)
      props.onAdd()
    }
  }

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 first:rounded-t last:rounded-b',
        {
          'cursor-not-allowed opacity-50': isSoldOut,
        }
      )}
    >
      <div className="flex flex-auto items-center gap-3">
        <div className="flex-initial">
          <img alt="ticket" className="h-4 w-4" src={`/icons/${props.icon}`} />
        </div>

        <div className="flex-auto space-y-1">
          <h4 className="font-medium leading-5">{props.name}</h4>

          <p className="text-sm leading-5">
            {priceFormatter.format(props.price)}{' '}
            {isSoldOut
              ? (
                  <span className="text-secondary">{t('common.soldOut')}</span>
                )
              : null}
            {!isSoldOut && (props.minimumQuantity || props.hasLimit)
              ? (
                  <span className="text-secondary">
                    {props.minimumQuantity > 0
                      ? (
                          <>
                    ·{' '}
                            {t('tickets.ticket.min', { value: props.minimumQuantity })}
                          </>
                        )
                      : null}

                    {props.hasLimit
                      ? (
                          <>
                    ·{' '}
                            {t('tickets.ticket.max', { value: props.available.ticket })}
                          </>
                        )
                      : null}
                  </span>
                )
              : null}
          </p>
        </div>
      </div>

      <div className="flex-0 flex items-center">
        <Button
          color="secondary"
          disabled={quantity === 0 || quantity <= props.minimumQuantity}
          onClick={handleRemove}
          size="sm"
          type="button"
          variant="outline"
        >
          <Minus />
        </Button>

        <div className="px-4">{quantity}</div>

        <Button
          color="secondary"
          disabled={availableQuantity === 0}
          onClick={handleAdd}
          size="sm"
          type="button"
          variant="outline"
        >
          <Plus />
        </Button>
      </div>
    </div>
  )
}
