export const Umbrella = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M7.00002 3.33977C6.52173 3.61591 6.35786 4.2275 6.634 4.70579L6.66185 4.75403C5.02616 5.95898 3.83759 7.68933 3.30669 9.67065C2.6889 11.9763 3.01232 14.4329 4.20579 16.5C4.3384 16.7297 4.55682 16.8973 4.813 16.9659C5.06918 17.0346 5.34213 16.9987 5.57182 16.866L11.634 13.366L15.634 20.2943C15.9101 20.7725 16.5217 20.9364 17 20.6603C17.4783 20.3841 17.6422 19.7725 17.366 19.2943L13.366 12.366L19.4282 8.86605C19.9065 8.58991 20.0704 7.97831 19.7943 7.50002C18.6008 5.43287 16.635 3.92448 14.3294 3.30669C12.3481 2.7758 10.2553 2.93996 8.3939 3.75403L8.36605 3.70579C8.08991 3.2275 7.47831 3.06363 7.00002 3.33977Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
