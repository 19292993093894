import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { MAP_PATH, TICKETS_PATH } from '../../app/router/paths'
import { KioskSpot } from '../../assets/icons/KioskSpot'
import { KioskTicket } from '../../assets/icons/KioskTicket'
import { KioskTimeoutDialog } from '../../components/Dialog/KioskTimeoutDialog'
import { KioskCard } from '../../components/KioskCard'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { BookingAvailability } from '../../store/bookingAvailabilitySlice'
import { cartSlice } from '../../store/cartSlice'
import { licenseSlice } from '../../store/licenseSlice'

interface Props {
  areSpotsAvailable: boolean
  areTicketsAvailable: boolean
}

export const ChooseProductKiosk = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const license = useAppSelector(licenseSlice.selectors.license)
  const dispatch = useAppDispatch()

  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl flex-auto pt-12">
        <div className="mb-6 text-center text-xl font-bold">
          {t('chooseProduct.kiosk.title')}
        </div>

        <div className="flex space-x-4 px-4">
          <KioskCard
            description={t('chooseProduct.kiosk.ticket.description')}
            icon={<KioskTicket />}
            isSoldOut={!props.areTicketsAvailable}
            onClick={() => {
              navigate(
                generatePath(TICKETS_PATH, { license: license?.license })
              )
              dispatch(
                cartSlice.actions.selectBookingType({
                  selectedBookingType: BookingAvailability.TICKETS,
                })
              )
            }}
            title={t('chooseProduct.kiosk.ticket.title')}
          />

          <KioskCard
            description={t('chooseProduct.kiosk.spot.description')}
            icon={<KioskSpot />}
            isSoldOut={!props.areSpotsAvailable}
            onClick={() => {
              navigate(generatePath(MAP_PATH, { license: license?.license }))
              dispatch(
                cartSlice.actions.selectBookingType({
                  selectedBookingType: BookingAvailability.SPOTS,
                })
              )
            }}
            title={t('chooseProduct.kiosk.spot.title')}
          />
        </div>
      </div>

      <KioskTimeoutDialog timeout={60000} />
    </>
  )
}
