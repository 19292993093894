export const Gazebo = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M3 7.80798V20H2C1.44772 20 1 20.4477 1 21C1 21.5522 1.44772 22 2 22H22C22.5523 22 23 21.5522 23 21C23 20.4477 22.5523 20 22 20H21V7.80798C21 6.61609 20.2944 5.53724 19.2025 5.05951L13.2025 2.43451C12.4359 2.09914 11.5641 2.09914 10.7975 2.43451L4.79754 5.05951C3.70558 5.53724 3 6.61609 3 7.80798ZM16.057 7.99995H7.94304C7.8397 8.97624 7.59455 10.114 7.19795 11.1175C6.92627 11.8049 6.5591 12.4926 6.06547 13.0242C5.77355 13.3385 5.41787 13.6171 5 13.7952V20H19V13.7952C18.5821 13.6171 18.2265 13.3385 17.9345 13.0242C17.4409 12.4926 17.0737 11.8049 16.8021 11.1175C16.4055 10.114 16.1603 8.97624 16.057 7.99995Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
