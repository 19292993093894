import { Dialog } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronUp } from '../../assets/icons/ChevronUp'
import { Close } from '../../assets/icons/Close'

import { LegendContent } from './LegendContent'

export const Legend = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        className="flex items-center rounded border border-gray-300 bg-white px-4 py-3"
        onClick={() => setIsOpen(true)}
      >
        <span className="text-lg font-bold text-gray-700">
          {t('map.legend.title')}
        </span>
        <ChevronUp className="hidden lg:block" />
      </button>

      <Dialog
        isOpen={isOpen}
        setIsOpen={(prev: boolean) => setIsOpen(!prev)}
        size="sm"
      >
        <div className="flex items-start gap-3 p-3">
          <LegendContent />
          <button className="ml-auto" onClick={() => setIsOpen(false)}>
            <Close />
          </button>
        </div>
      </Dialog>
    </>
  )
}
