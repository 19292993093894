import { useTranslation } from 'react-i18next'

import { AvailableSpot } from '../../assets/icons/AvailableSpot'
import { BeachHut } from '../../assets/icons/BeachHut'
import { Gazebo } from '../../assets/icons/Gazebo'
import { NotAvailableSpot } from '../../assets/icons/NotAvailableSpot'
import { Parking } from '../../assets/icons/Parking'
import { Sunbed } from '../../assets/icons/Sunbed'
import { Umbrella } from '../../assets/icons/Umbrella'

export const LegendContent = () => {
  const { t } = useTranslation()
  const LEGEND_ITEMS = [
    {
      icon: <Umbrella />,
      label: t('map.legend.umbrella'),
    },
    {
      icon: <Sunbed />,
      label: t('map.legend.sunbed'),
    },
    {
      icon: <Gazebo />,
      label: t('map.legend.gazebo'),
    },
    {
      icon: <BeachHut />,
      label: t('map.legend.beachHut'),
    },
    {
      icon: <Parking />,
      label: t('map.legend.parking'),
    },
  ]
  return (
    <div className="grow">
      <div className="mb-2 grid grid-cols-2 gap-2 lg:grid-cols-3 lg:gap-4">
        {LEGEND_ITEMS.map((element) => (
          <div
            className="mb-2 flex items-center gap-1 text-gray-500"
            key={element.label}
          >
            {element.icon}
            <span className="text-sm text-gray-900">{element.label}</span>
          </div>
        ))}
      </div>

      <div className="mb-2 grid grid-cols-2 gap-2 border-t border-gray-300 pt-2 lg:gap-4">
        <div className="mb-2 flex items-center gap-1">
          <AvailableSpot />
          <span className="text-sm text-gray-900">
            {t('map.legend.available')}
          </span>
        </div>
        <div className="mb-2 flex items-center gap-1">
          <NotAvailableSpot />
          <span className="text-sm text-gray-900">
            {t('map.legend.notAvailable')}
          </span>
        </div>
      </div>
    </div>
  )
}
