export const NotAvailableSpot = (
  props: React.ComponentPropsWithoutRef<'svg'>
) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" fill="#EFEFF0" r="10" />
      <rect
        fill="#D1D1D6"
        height="26"
        transform="rotate(45 20.4854 2.10059)"
        width="2"
        x="20.4854"
        y="2.10059"
      />
      <rect
        fill="#D1D1D6"
        height="26"
        transform="rotate(-45 2.10059 3.51465)"
        width="2"
        x="2.10059"
        y="3.51465"
      />
    </svg>
  )
}
