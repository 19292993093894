import { cn } from '@spiaggeit/spit-ui'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDebounceCallback } from 'usehooks-ts'

import { I18N_QS_KEY } from '../../app/i18n'
import { KioskTimeoutDialog } from '../../components/Dialog/KioskTimeoutDialog'
import { FeesDialog } from '../../components/FeesDialog'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { BookingFlow } from '../../store/bookingAvailabilitySlice'
import { cartPayloadSelector, cartSlice } from '../../store/cartSlice'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import { licenseSlice } from '../../store/licenseSlice'
import { servicesSlice } from '../../store/servicesSlice'
import { toastSlice } from '../../store/toastSlice'
import { BOOKING_FLOW_QUERY_PARAM } from '../../utils/bookingFlow'
import { getLegacyUrl } from '../../utils/legacyUrl'

import { Group } from './Group'
import { MobileDrawer } from './MobileDrawer'
import { Sidebar } from './Sidebar'
import { SubmitButton } from './SubmitButton'

export const TicketsRoute = () => {
  const { t, i18n } = useTranslation()
  const [isFeesDialogOpen, setIsFeesDialogOpen] = useState(false)
  const dispatch = useAppDispatch()
  const data = useAppSelector(servicesSlice.selectors.self)
  const totalQuantity = useAppSelector(cartSlice.selectors.totalQuantity)
  const cartPayload = useAppSelector(cartPayloadSelector)
  const inputRef = useRef<HTMLInputElement>(null)
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const license = useAppSelector(licenseSlice.selectors.license)
  const [isLoading, setIsLoading] = useState(false)

  const quoteCallback = useCallback(() => {
    dispatch(cartSlice.actions.quote())
  }, [dispatch])
  const quote = useDebounceCallback(quoteCallback, 500)

  useEffect(() => {
    dispatch(servicesSlice.actions.load())
  }, [dispatch, period])

  useEffect(() => {
    if (totalQuantity === 0) return
    quote()
  }, [dispatch, period, totalQuantity, quote])

  useEffect(() => {
    if (inputRef.current && cartPayload) {
      inputRef.current.value = JSON.stringify(cartPayload)
    }
  }, [cartPayload])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (data.status !== 'success') return
    setIsLoading(true)
    const target = e.target
    e.preventDefault()

    if (!(target instanceof HTMLFormElement)) {
      dispatch(toastSlice.actions.show(t('tickets.error.generic')))
      setIsLoading(false)
    } else if (
      data.data.hasLimit &&
      data.data.availableQuantity < totalQuantity
    ) {
      dispatch(toastSlice.actions.show(t('tickets.error.soldOut')))
      setIsLoading(false)
    } else {
      dispatch(cartSlice.actions.quote())
        .then(() => {
          if (totalQuantity === 0) {
            dispatch(toastSlice.actions.show(t('tickets.error.min')))
          } else {
            target.submit()
          }
        })
        .finally(() => {
          setIsLoading(false)
          dispatch(insertPeriodSlice.actions.setBookingPeriod(null))
          dispatch(insertPeriodSlice.actions.setPeriod(null))
        })
    }
  }

  if (data.status !== 'success' || !license) {
    return null
  }

  const hasReachedLimit = data.data.hasLimit
    ? totalQuantity === data.data.availableQuantity
    : false

  return (
    <>
      <main className="min-h-full flex-grow bg-[#fafafa] pb-28 pt-8 lg:pb-8">
        <form
          action={`${getLegacyUrl()}/${license.license}/registrationLogin/?${I18N_QS_KEY}=${i18n.language}&${BOOKING_FLOW_QUERY_PARAM}=${BookingFlow.TICKETS}`}
          className="mx-auto w-full max-w-screen-xl px-4"
          method="POST"
          onSubmit={onSubmit}
        >
          <input
            id="ybBookingServicesFormCart"
            name="cart"
            ref={inputRef}
            type="hidden"
          />

          <div className="gap-8 md:grid md:grid-cols-12">
            <div className="md:col-span-7">
              <div
                className={cn(
                  'rounded-sm border-l-4 px-3 py-2 text-sm md:text-base',
                  {
                    'border-l-red-200 bg-red-25': hasReachedLimit,
                    'border-l-yellow-200 bg-yellow-25': !hasReachedLimit,
                  }
                )}
              >
                {hasReachedLimit
                  ? (
                      <p>
                        <Trans i18nKey="tickets.banner.soldOut" />
                      </p>
                    )
                  : null}

                <p>
                  <Trans i18nKey="tickets.banner.generic" />
                </p>
              </div>

              <div className="divide-y divide-gray-100">
                {data.data.serviceGroups.map((group) => (
                  <Group
                    availableQuantity={
                      group.hasLimit ? group.availableQuantity : Infinity
                    }
                    hasLimit={group.hasLimit}
                    key={group.serviceGroupId}
                    name={group.name}
                    onAdd={() => null}
                    onRemove={() => null}
                    tickets={group.services}
                  />
                ))}
              </div>

              <SubmitButton className="hidden md:block" isLoading={isLoading} />
            </div>

            <Sidebar openFeesDialog={() => setIsFeesDialogOpen(true)} />
          </div>

          <MobileDrawer openFeesDialog={() => setIsFeesDialogOpen(true)} />
        </form>

        <FeesDialog isOpen={isFeesDialogOpen} open={setIsFeesDialogOpen} />
      </main>

      <KioskTimeoutDialog timeout={180000} />
    </>
  )
}
