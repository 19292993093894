import { Button } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'

import { Cart } from '../../assets/icons/Cart'
import { ChevronUp } from '../../assets/icons/ChevronUp'
import { ExclamationMark } from '../../assets/icons/ExclamationMark'
import { Minus } from '../../assets/icons/Minus'
import { Plus } from '../../assets/icons/Plus'
import { useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice'
import { priceFormatter } from '../../utils/price'

import { Legend } from './LegendDialog'

interface Props {
  zoomIn(): void
  zoomOut(): void
}

export const CartBottomDrawer = (props: Props) => {
  const { t } = useTranslation()
  const cartItems = useAppSelector(cartSlice.selectors.items)
  const cartTotal = useAppSelector(cartSlice.selectors.total)

  return (
    <div className="absolute bottom-0 left-0 right-0 flex h-fit grow flex-col gap-3 rounded-tl-lg rounded-tr-lg bg-white p-4 shadow lg:rounded-none">
      {/* TODO: add expanded version + cart items */}
      <Button
        aria-label={t('common.openImperativeVerb')}
        className="mx-auto border-none p-0 lg:hidden"
        color="null"
        type="button"
      >
        <ChevronUp />
      </Button>
      <div className="flex justify-between">
        <div className="hidden gap-8 lg:flex">
          <div className="flex gap-2">
            <button
              className="rounded border border-gray-300 p-5 disabled:opacity-20"
              onClick={props.zoomOut}
            >
              <Minus />
            </button>
            <button
              className="rounded border border-gray-300 p-5"
              onClick={props.zoomIn}
            >
              <Plus />
            </button>
          </div>
          <Legend />
        </div>
        <div className="flex basis-full justify-between gap-3 lg:basis-auto">
          <button className="relative">
            <span className="absolute bottom-[2rem] right-[-0.5rem] flex min-h-5 min-w-5 items-center justify-center rounded-circle bg-yellow-200 text-xs text-white">
              {Object.keys(cartItems).length}
            </span>
            <Cart />
          </button>
          <div className="flex flex-col">
            <span className="font-bold text-primary">
              {priceFormatter.format(cartTotal || 0)}
            </span>
            <button>
              <span className="inline-flex gap-1 text-secondary underline">
                {t('tickets.summary.includedFees')}
                <ExclamationMark />
              </span>
            </button>
          </div>
          <Button disabled={Object.keys(cartItems).length === 0}>
            {t('common.proceed')}
          </Button>
        </div>
      </div>
    </div>
  )
}
