import { init, MapCanvasApi } from '@spiaggeit/map-canvas'
import { Color } from '@spiaggeit/spit-core'
import { useEffect, useRef } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { INSERT_PERIOD_PATH } from '../../app/router/paths'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import { licenseSlice } from '../../store/licenseSlice'
import { loadMapData, mapSelectors, mapSlice } from '../../store/mapSlice'
import { MAP_CELL_SIZE } from '../../utils/constants'

import { CartBottomDrawer } from './CartBottomDrawer'
import { ElementDetail } from './ElementDetail'
import { FirstVisitDialog } from './FirstVisitDialog'
import { Legend } from './LegendDialog'

export const MapRoute = () => {
  const mapCanvasApiRef = useRef<null | MapCanvasApi>(null)
  const license = useAppSelector(licenseSlice.selectors.license)
  const dispatch = useAppDispatch()
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const navigate = useNavigate()
  if (!period || !period.end || !period.start) {
    navigate(generatePath(INSERT_PERIOD_PATH, { license: license?.license }))
  }

  const mapData = useAppSelector(mapSelectors.mapData)?.value
  const isLoading = useAppSelector(mapSelectors.isLoading)
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  useEffect(() => {
    dispatch(loadMapData())
  }, [dispatch])
  useEffect(() => {
    if (
      !mapData ||
      !mapData?.elements ||
      !mapData?.background ||
      !mapData?.size
    )
      return
    init({
      events: {
        onMapElementClick(id) {
          mapData?.elements &&
            mapData?.elements.find((element) => {
              if (
                element.id == id &&
                element.style?.iconColor === Color.GREEN
              ) {
                dispatch(mapSlice.actions.selectElement(element))
              }
            })
        },
      },
      opts: {
        assetsBaseUrl: 'https://assets.staging.spiagge.it/booking/map',
        background: mapData.background,
        containerId: 'canvas',
        elements: mapData?.elements,
        size: {
          height: mapData?.size.height * MAP_CELL_SIZE,
          width: mapData?.size.width * MAP_CELL_SIZE,
        },
      },
    })
      .then((mapCanvasApi) => {
        mapCanvasApiRef.current = mapCanvasApi
      })
      // TODO: handle error
      // eslint-disable-next-line no-console
      .catch(console.error)

    return () => {
      mapCanvasApiRef.current?.destroy()
    }
  }, [mapData])

  if (isLoading) return <span>Loading...</span> // TODO: add loading spinner
  return (
    <main className="relative flex flex-1">
      <div className="flex-1" id="canvas"></div>
      <span className="fixed left-4 top-32 z-50 lg:hidden">
        <Legend />
      </span>
      <FirstVisitDialog />
      <CartBottomDrawer
        zoomIn={() => mapCanvasApiRef.current?.zoomIn()}
        zoomOut={() => mapCanvasApiRef.current?.zoomOut()}
      />
      {selectedElement && <ElementDetail />}
    </main>
  )
}
