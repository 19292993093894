import { PayloadAction, createSelector } from '@reduxjs/toolkit'
import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { DateTime } from 'luxon'

import { createAppSlice } from './createAppSlice'

type Period = { start: string; end: string }

export type PeriodSliceState = {
  period: Period | null
  bookingPeriod: BookingPeriod | null
}

const initialState: PeriodSliceState = {
  bookingPeriod: BookingPeriod.ALL_DAY,
  period: null,
}

export const insertPeriodSlice = createAppSlice({
  initialState,
  name: 'insertPeriod',
  reducers: (create) => ({
    setBookingPeriod: create.reducer(
      (state, action: PayloadAction<BookingPeriod | null>) => {
        state.bookingPeriod = action.payload
      }
    ),
    setPeriod: create.reducer((state, action: PayloadAction<Period | null>) => {
      state.period = action.payload
    }),
  }),
  selectors: {
    bookingPeriod: (state) => state.bookingPeriod,
    period: (state) => state.period,
  },
})

export const periodAsUnixTimeSelector = createSelector(
  insertPeriodSlice.selectors.period,
  (period) => {
    if (!period) return null

    return {
      end: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
        zone: 'utc',
      }).toSeconds(),
      start: DateTime.fromFormat(period.start, 'yyyy-LL-dd', {
        zone: 'utc',
      }).toSeconds(),
    }
  }
)
