import create from '@kodingdotninja/use-tailwind-breakpoint'
import { Button, Dialog } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../../tailwind.config'
import { DiagonalResize } from '../../assets/icons/DiagonalResize'
import { Move } from '../../assets/icons/Move'

import { LegendContent } from './LegendContent'

export const FirstVisitDialog = () => {
  const config = resolveConfig(tailwindConfig)
  const { useBreakpoint } = create(config.theme.screens)
  const isLargeScreen = useBreakpoint('lg')

  const { t } = useTranslation()
  const [dialogIsOpen, setDialogIsOpen] = useState(true)

  return (
    <Dialog isOpen={dialogIsOpen} size="sm">
      <div className="flex h-fit grow flex-col gap-3 p-4">
        <h3 className="text-gray-800 text-lg font-bold">
          {t('map.dialog.title')}
        </h3>
        {isLargeScreen
          ? (
              <LegendContent />
            )
          : (
              <>
                <div className="flex items-center gap-2">
                  <span className="shrink-0">
                    <DiagonalResize />
                  </span>
                  <span className="text-gray-600 text-sm font-medium">
                    {t('map.dialog.resizeInstructions')}
                  </span>
                </div>
                <div className="mb-3 flex items-center gap-2">
                  <span className="shrink-0">
                    <Move />
                  </span>
                  <span className="text-gray-600 text-sm font-medium">
                    {t('map.dialog.moveInstructions')}
                  </span>
                </div>
              </>
            )}
        <Button fullWidth onClick={() => setDialogIsOpen(false)}>
          {t('map.dialog.closeButton')}
        </Button>
      </div>
    </Dialog>
  )
}
