import { DateTime } from 'luxon'

export function fromISOtoTimestamp(value?: string | null): number | null {
  if (typeof value !== 'string') {
    return null
  }

  const parsedFrom = DateTime.fromISO(value, { zone: 'utc' })
  if (!parsedFrom.isValid) {
    return null
  }

  return parsedFrom.toUnixInteger()
}
