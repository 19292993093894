import { Button } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { MAP_PATH, TICKETS_PATH } from '../../app/router/paths'
import { Card } from '../../components/Card'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { BookingAvailability } from '../../store/bookingAvailabilitySlice'
import { cartSlice } from '../../store/cartSlice'
import { licenseSlice } from '../../store/licenseSlice'

interface Props {
  areSpotsAvailable: boolean
  areTicketsAvailable: boolean
}

export const ChooseProductDefault = (props: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const license = useAppSelector(licenseSlice.selectors.license)
  const dispatch = useAppDispatch()

  return (
    <main className="min-h-full flex-grow bg-[#fafafa] px-4 pb-28 pt-8 lg:pb-8">
      <div className="flex flex-col items-center justify-center space-y-4 md:mt-20 md:flex-row md:space-x-8 md:space-y-0">
        <Card
          action={
            <Button
              disabled={!props.areSpotsAvailable}
              fullWidth
              onClick={() => {
                dispatch(
                  cartSlice.actions.selectBookingType({
                    selectedBookingType: BookingAvailability.SPOTS,
                  })
                )
                navigate(generatePath(MAP_PATH, { license: license?.license }))
              }}
              type="button"
            >
              {t('chooseProduct.spot.book')}
            </Button>
          }
          isSoldOut={!props.areSpotsAvailable}
          list={[
            t('chooseProduct.spot.listItem00'),
            t('chooseProduct.spot.listItem01'),
          ]}
          title={t('chooseProduct.spot.title')}
        />

        <div className="hidden md:block">{t('chooseProduct.or')}</div>

        <Card
          action={
            <Button
              color="primary"
              disabled={!props.areTicketsAvailable}
              fullWidth
              onClick={() => {
                navigate(
                  generatePath(TICKETS_PATH, { license: license?.license })
                )
                dispatch(
                  cartSlice.actions.selectBookingType({
                    selectedBookingType: BookingAvailability.TICKETS,
                  })
                )
              }}
              type="button"
              variant="outline"
            >
              {t('chooseProduct.ticket.book')}
            </Button>
          }
          isSoldOut={!props.areTicketsAvailable}
          list={[
            t('chooseProduct.ticket.listItem00'),
            t('chooseProduct.ticket.listItem01'),
          ]}
          title={t('chooseProduct.ticket.title')}
        />
      </div>
    </main>
  )
}
