import { useEffect } from 'react'

import { ItemQuantityController } from '../../components/ItemQuantityController/ItemQuantityController'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice'

interface Props {
  available: {
    global: number
    group: number
    ticket: number
  }
  hasLimit: boolean
  icon: string
  id: number
  name: string
  minimumQuantity: number
  price: number
  onAdd: VoidFunction
  onRemove: VoidFunction
}

export const Ticket = (props: Props) => {
  const dispatch = useAppDispatch()
  const { id, minimumQuantity } = props
  const quantity = useAppSelector(
    (state) => cartSlice.selectors.itemById(state, id)?.quantity ?? 0
  )

  // Initialize quantity on mount
  useEffect(() => {
    dispatch(
      cartSlice.actions.set({
        id,
        quantity: minimumQuantity,
      })
    )
  }, [dispatch, id, minimumQuantity])

  const handleAdd = () => {
    dispatch(cartSlice.actions.add({ id }))
  }

  const handleRemove = () => {
    dispatch(cartSlice.actions.decrease({ id }))
  }

  return (
    <ItemQuantityController
      {...props}
      initialQuantity={quantity}
      onAdd={handleAdd}
      onRemove={handleRemove}
    />
  )
}
