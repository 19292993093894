/* eslint-disable import/no-default-export */
import plugin from 'tailwindcss/plugin'

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './node_modules/@spiaggeit/spit-datepicker/**/*.js',
    './node_modules/@spiaggeit/spit-ui/**/*.js',
  ],
  theme: {
    borderRadius: {
      none: '0px',
      xs: '0.125rem',
      sm: '0.25rem',
      DEFAULT: '0.5rem',
      md: '0.75rem',
      lg: '1rem',
      circle: '50%',
    },
    boxShadow: {
      sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
      '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
      inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
      none: 'none',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      inherit: 'inherit',
      white: 'rgb(var(--white-rgb) / <alpha-value>)',
      black: 'rgb(var(--black-rgb) / <alpha-value>)',
      gray: {
        25: 'rgb(var(--gray-25-rgb) / <alpha-value>)',
        50: 'rgb(var(--gray-50-rgb) / <alpha-value>)',
        100: 'rgb(var(--gray-100-rgb) / <alpha-value>)',
        200: 'rgb(var(--gray-200-rgb) / <alpha-value>)',
        300: 'rgb(var(--gray-300-rgb) / <alpha-value>)',
        400: 'rgb(var(--gray-400-rgb) / <alpha-value>)',
        500: 'rgb(var(--gray-500-rgb) / <alpha-value>)',
        700: 'rgb(var(--gray-700-rgb) / <alpha-value>)',
        900: 'rgb(var(--gray-900-rgb) / <alpha-value>)',
      },
      yellow: {
        25: 'rgb(var(--yellow-25-rgb) / <alpha-value>)',
        50: 'rgb(var(--yellow-50-rgb) / <alpha-value>)',
        100: 'rgb(var(--yellow-100-rgb) / <alpha-value>)',
        200: 'rgb(var(--yellow-200-rgb) / <alpha-value>)',
        300: 'rgb(var(--yellow-300-rgb) / <alpha-value>)',
        400: 'rgb(var(--yellow-400-rgb) / <alpha-value>)',
        500: 'rgb(var(--yellow-500-rgb) / <alpha-value>)',
        600: 'rgb(var(--yellow-600-rgb) / <alpha-value>)',
        700: 'rgb(var(--yellow-700-rgb) / <alpha-value>)',
      },
      blue: {
        25: 'rgb(var(--blue-25-rgb) / <alpha-value>)',
        50: 'rgb(var(--blue-50-rgb) / <alpha-value>)',
        100: 'rgb(var(--blue-100-rgb) / <alpha-value>)',
        200: 'rgb(var(--blue-200-rgb) / <alpha-value>)',
        300: 'rgb(var(--blue-300-rgb) / <alpha-value>)',
        400: 'rgb(var(--blue-400-rgb) / <alpha-value>)',
        500: 'rgb(var(--blue-500-rgb) / <alpha-value>)',
        600: 'rgb(var(--blue-600-rgb) / <alpha-value>)',
        700: 'rgb(var(--blue-700-rgb) / <alpha-value>)',
      },
      green: {
        25: 'rgb(var(--green-25-rgb) / <alpha-value>)',
        50: 'rgb(var(--green-50-rgb) / <alpha-value>)',
        100: 'rgb(var(--green-100-rgb) / <alpha-value>)',
        200: 'rgb(var(--green-200-rgb) / <alpha-value>)',
        300: 'rgb(var(--green-300-rgb) / <alpha-value>)',
        400: 'rgb(var(--green-400-rgb) / <alpha-value>)',
        500: 'rgb(var(--green-500-rgb) / <alpha-value>)',
        600: 'rgb(var(--green-600-rgb) / <alpha-value>)',
        700: 'rgb(var(--green-700-rgb) / <alpha-value>)',
      },
      red: {
        25: 'rgb(var(--red-25-rgb) / <alpha-value>)',
        50: 'rgb(var(--red-50-rgb) / <alpha-value>)',
        100: 'rgb(var(--red-100-rgb) / <alpha-value>)',
        200: 'rgb(var(--red-200-rgb) / <alpha-value>)',
        300: 'rgb(var(--red-300-rgb) / <alpha-value>)',
        400: 'rgb(var(--red-400-rgb) / <alpha-value>)',
        500: 'rgb(var(--red-500-rgb) / <alpha-value>)',
        600: 'rgb(var(--red-600-rgb) / <alpha-value>)',
        700: 'rgb(var(--red-700-rgb) / <alpha-value>)',
      },
      purple: {
        25: 'rgb(var(--purple-25-rgb) / <alpha-value>)',
        50: 'rgb(var(--purple-50-rgb) / <alpha-value>)',
        100: 'rgb(var(--purple-100-rgb) / <alpha-value>)',
        200: 'rgb(var(--purple-200-rgb) / <alpha-value>)',
        300: 'rgb(var(--purple-300-rgb) / <alpha-value>)',
        400: 'rgb(var(--purple-400-rgb) / <alpha-value>)',
        500: 'rgb(var(--purple-500-rgb) / <alpha-value>)',
        600: 'rgb(var(--purple-600-rgb) / <alpha-value>)',
        700: 'rgb(var(--purple-700-rgb) / <alpha-value>)',
      },
      magenta: {
        25: 'rgb(var(--magenta-25-rgb) / <alpha-value>)',
        50: 'rgb(var(--magenta-50-rgb) / <alpha-value>)',
        100: 'rgb(var(--magenta-100-rgb) / <alpha-value>)',
        200: 'rgb(var(--magenta-200-rgb) / <alpha-value>)',
        300: 'rgb(var(--magenta-300-rgb) / <alpha-value>)',
        400: 'rgb(var(--magenta-400-rgb) / <alpha-value>)',
        500: 'rgb(var(--magenta-500-rgb) / <alpha-value>)',
        600: 'rgb(var(--magenta-600-rgb) / <alpha-value>)',
        700: 'rgb(var(--magenta-700-rgb) / <alpha-value>)',
      },
    },
    container: {
      center: true,
      padding: '1rem',
      screens: {},
    },
    fontFamily: {
      sans: [
        'var(--font-roboto)',
        'ui-sans-serif',
        'system-ui',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
    },
    fontSize: {
      xs: ['0.75rem', { lineHeight: '1rem' }],
      sm: ['0.875rem', { lineHeight: '1.25rem' }],
      base: ['1rem', { lineHeight: '1.5rem' }],
      lg: ['1.25rem', { lineHeight: '1.5rem' }],
      xl: ['1.5rem', { lineHeight: '1.75rem' }],
      '2xl': ['2rem', { lineHeight: '2.25rem' }],
      '3xl': ['3rem', { lineHeight: '3.25rem' }],
      '6xl': ['4rem', { lineHeight: '4rem' }],
    },
    fontWeight: {
      normal: '400',
      medium: '500',
      bold: '700',
    },
    screens: {
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
    },
    extend: {
      animation: {},
      borderColor: ({ theme }) => ({
        DEFAULT: theme('colors.gray.100', 'currentColor'),
      }),
      textColor: {
        primary: 'rgb(var(--gray-900-rgb) / <alpha-value>)',
        'primary-inverted': 'rgb(var(--white-rgb) / <alpha-value>)',
        secondary: 'rgb(var(--gray-500-rgb) / <alpha-value>)',
        tertiary: 'rgb(var(--gray-400-rgb) / <alpha-value>)',
        link: 'rgb(var(--blue-300-rgb) / <alpha-value>)',
        'link-hover': 'rgb(var(--blue-500-rgb) / <alpha-value>)',
        'link-visited': 'rgb(var(--purple-600-rgb) / <alpha-value>)',
        critical: 'rgb(var(--red-600-rgb) / <alpha-value>)',
        warning: 'rgb(var(--yellow-600-rgb) / <alpha-value>)',
        success: 'rgb(var(--green-600-rgb) / <alpha-value>)',
        info: 'rgb(var(--blue-500-rgb) / <alpha-value>)',
        'deco-1': 'rgb(var(--purple-600-rgb) / <alpha-value>)',
        'deco-2': 'rgb(var(--magenta-600-rgb) / <alpha-value>)',
      },
      zIndex: {
        100: '100',
        200: '200',
        300: '300',
        500: '500',
        700: '700',
        900: '900',
      },
      keyframes: {
        // TODO: change to use the new keyframes
        overlayShow: {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        contentShow: {
          from: {
            opacity: '0',
          },
          to: { opacity: '1' },
        },
        slideDown: {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        slideUp: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        // New keyframes
        fadeIn: {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        fadeOut: {
          from: { opacity: '1' },
          to: { opacity: '0' },
        },
        // Slide in from bottom
        slideInFB: {
          from: { transform: 'translateY(100%)' },
          to: { transform: 'translateY(0)' },
        },
      },
      animation: {
        overlayShow: 'overlayShow 500ms cubic-bezier(0.16, 1, 0.3, 1)',
        contentShow: 'contentShow 500ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideDown: 'slideDown 200ms ease-in-out',
        slideUp: 'slideUp 200ms ease-in-out',
        fadeIn: 'fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1)',
        fadeOut: 'fadeOut 100ms ease-in',
        // Slide in from bottom
        slideInFB:
          'slideInFB 150ms cubic-bezier(0.16, 1, 0.3, 1), fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1)',
      },
    },
  },
  plugins: [
    plugin(function hideScrollbar({ addUtilities }) {
      addUtilities({
        '.hide-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
        '.hide-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
      })
    }),
  ],
}
