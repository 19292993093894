import * as rd from '@devexperts/remote-data-ts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  MAP_PATH,
  TICKETS_PATH,
} from '../../../app/router/paths'
import { useAppSelector } from '../../../hooks/store'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import {
  BookingAvailability,
  bookingAvailabilitySlice,
} from '../../../store/bookingAvailabilitySlice'
import { cartSlice } from '../../../store/cartSlice'
import { licenseSlice } from '../../../store/licenseSlice'

import { Step } from './Step'

function getFirstStepLabel(
  bookingAvailability: BookingAvailability,
  currentPath: string
) {
  if (
    bookingAvailability === BookingAvailability.TICKETS ||
    currentPath === TICKETS_PATH
  ) {
    return 'header.progressBar.step.tickets'
  }

  return 'header.progressBar.step.spots'
}

export const ProgressBar = () => {
  const { t } = useTranslation()
  const license = useAppSelector(licenseSlice.selectors.license)
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )
  const { currentPath } = useCurrentPath()
  const selectedBookingType = useAppSelector(
    cartSlice.selectors.selectedBookingType
  )

  const stepHref = useMemo(() => {
    if (selectedBookingType === BookingAvailability.TICKETS)
      return generatePath(TICKETS_PATH, { license: license?.license })
    else if (selectedBookingType === BookingAvailability.SPOTS)
      return generatePath(MAP_PATH, { license: license?.license })
    return '' // TODO: add logic for future step (such as details or pay)
  }, [currentPath, TICKETS_PATH, generatePath, license, MAP_PATH])

  if (
    !license ||
    !rd.isSuccess(bookingAvailability) ||
    ((currentPath === INSERT_PERIOD_PATH ||
      currentPath === CHOOSE_PRODUCT_PATH) &&
      rd.isSuccess(bookingAvailability) &&
      bookingAvailability.value.type === BookingAvailability.SPOTS_AND_TICKETS)
  ) {
    return null
  }

  return (
    <div className="flex min-w-20 items-center px-4 pb-5 md:p-0 md:pt-1">
      <Step
        href={stepHref}
        label={t(
          getFirstStepLabel(bookingAvailability.value.type, currentPath)
        )}
      />

      <div className="flex-auto border-t" />

      <Step href="TODO" label={t('header.progressBar.step.summary')} />

      <div className="flex-auto border-t" />

      <Step href="TODO" label={t('header.progressBar.step.payment')} />
    </div>
  )
}
