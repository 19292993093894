import { BookingFlow } from '../store/bookingAvailabilitySlice'

export const BOOKING_FLOW_QUERY_PARAM = 'bookingFlow'
export function getBookingFlowFromSearchParams(
  searchParams: URLSearchParams
): BookingFlow | undefined {
  const bookingFlow = searchParams.get(BOOKING_FLOW_QUERY_PARAM)

  return parseBookingFlow(bookingFlow)
}

function parseBookingFlow(data: unknown): BookingFlow | undefined {
  if (typeof data !== 'string') {
    return undefined
  }

  if (data === '0') {
    return BookingFlow.SPOTS
  }

  if (data === '1') {
    return BookingFlow.TICKETS
  }
}
