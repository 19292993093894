import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { appSlice } from '../store/appSlice'
import { bookingAvailabilitySlice } from '../store/bookingAvailabilitySlice'
import { licenseSlice } from '../store/licenseSlice'
import { getBookingFlowFromSearchParams } from '../utils/bookingFlow'

import { useAppDispatch, useAppSelector } from './store'

export function useApp() {
  const dispatch = useAppDispatch()
  const routeParams = useParams()
  const licenseStatus = useAppSelector(licenseSlice.selectors.status)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    dispatch(appSlice.actions.setupByUrl(window.location.href))
  }, [dispatch])

  useEffect(() => {
    if (routeParams.license && licenseStatus === 'idle') {
      dispatch(licenseSlice.actions.loadLicense(routeParams.license))
    }
  }, [dispatch, routeParams, licenseStatus, searchParams])

  useEffect(() => {
    if (licenseStatus === 'success') {
      dispatch(
        bookingAvailabilitySlice.actions.load({
          bookingFlow: getBookingFlowFromSearchParams(searchParams),
        })
      )
    }
  }, [licenseStatus])
}
