import { BookingPeriod } from '@spiaggeit/spit-datepicker'

import { httpClient } from '../lib/http/HttpClient'
import { ServiceGroup, ServicesInfoResponse } from '../models/service'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'
import {
  insertPeriodSlice,
  periodAsUnixTimeSelector,
} from './insertPeriodSlice'
import { licenseSlice } from './licenseSlice'

type ServicesSliceState =
  | {
      data: {
        availableQuantity: number
        hasLimit: boolean
        serviceGroups: ServiceGroup[]
      }
      status: 'success'
    }
  | {
      error: string
      status: 'error'
    }
  | {
      status: 'idle' | 'loading'
    }

const initialState: ServicesSliceState = {
  status: 'idle',
}

function mapBookingPeriod(
  value: ReturnType<typeof insertPeriodSlice.selectors.bookingPeriod>
) {
  switch (value) {
    case BookingPeriod.ALL_DAY:
      return 0
    case BookingPeriod.MORNING:
      return 1
    case BookingPeriod.AFTERNOON:
      return 2
    default:
      return 0
  }
}

export const servicesSlice = createAppSlice({
  initialState: initialState satisfies ServicesSliceState as ServicesSliceState,
  name: 'services',
  reducers: (create) => ({
    load: create.asyncThunk<
      ServicesInfoResponse,
      void,
      { rejectValue: string }
    >(
      async (_, thunkApi): Promise<ServicesInfoResponse> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.license(rootState)
        const country = appSlice.selectors.country(rootState)
        const period = periodAsUnixTimeSelector(rootState)
        const bookingPeriod =
          insertPeriodSlice.selectors.bookingPeriod(rootState)
        if (!license) {
          throw thunkApi.rejectWithValue('License not found')
        }

        if (!period) {
          throw thunkApi.rejectWithValue('Period not found')
        }

        const res = await httpClient.fetch<ServicesInfoResponse>(
          `beaches/${license.license}/services-info`,
          country,
          {
            body: JSON.stringify({
              from: period.start,
              halfDay: mapBookingPeriod(bookingPeriod),
              purchaseOnlineAlone: true, //E. che vuol dire purchaseOnlineAlone?
              serviceType: 3,
              to: period.end,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        )

        if (res.status === 'error') {
          throw thunkApi.rejectWithValue(res.error)
        }

        return res.data
      },
      {
        fulfilled: (_, action) => {
          return {
            data: {
              availableQuantity: action.payload.result.limits.availableQuantity,
              hasLimit: action.payload.result.limits.hasLimit,
              serviceGroups:
                action.payload.result.limits.serviceGroupLimits.map(
                  (group) => ({
                    availableQuantity: group.availableQuantity,
                    hasLimit: group.hasLimit,
                    name: group.name,
                    serviceGroupId: group.serviceGroupId,
                    services: group.serviceLimits,
                  })
                ),
            },
            status: 'success',
          }
        },
        pending: () => {
          return {
            status: 'loading',
          }
        },
        rejected: (_, action) => {
          return {
            error: action.payload ?? 'Unknown error',
            status: 'error',
          }
        },
      }
    ),
  }),
  selectors: {
    data: (state) => (state.status === 'success' ? state.data : null),
    self: (state) => state,
  },
})
