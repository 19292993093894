import { DateTime } from 'luxon'
import React from 'react'
import { useSelector } from 'react-redux'

import { LockDates } from '../models/insertPeriod'
import { insertPeriodSlice } from '../store/insertPeriodSlice'
import { licenseSlice } from '../store/licenseSlice'

import { useAppDispatch } from './store'

export function usePeriod(lockDates: LockDates) {
  const license = useSelector(licenseSlice.selectors.license)
  const isBookingEnabled = useSelector(licenseSlice.selectors.isBookingEnabled)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (!license || !isBookingEnabled) {
      // cannot set period
      return
    }

    if (lockDates.minDate > lockDates.maxDate) {
      // TODO throw / log error?
      return
    }

    const defaultStart = DateTime.fromMillis(lockDates.minDate)

    if (!defaultStart.isValid) {
      return
    }

    const period = {
      end: defaultStart,
      start: defaultStart,
    }

    // check if period is valid
    function isValidPeriod(): boolean {
      const lockStart = DateTime.fromMillis(lockDates.minDate)
      const lockEnd = DateTime.fromMillis(lockDates.maxDate)

      if (
        period.start < lockStart ||
        period.start > lockEnd ||
        period.end < lockStart ||
        period.end > lockEnd
      ) {
        return false
      }

      return true
    }

    if (isValidPeriod()) {
      dispatch(
        insertPeriodSlice.actions.setPeriod({
          end: period.end.toISODate(),
          start: period.start.toISODate(),
        })
      )
    } else {
      dispatch(insertPeriodSlice.actions.setPeriod(null))
    }
  }, [
    dispatch,
    isBookingEnabled,
    license,
    lockDates.maxDate,
    lockDates.minDate,
  ])
}
