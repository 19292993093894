import * as rd from '@devexperts/remote-data-ts'
import { generatePath } from 'react-router-dom'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  LANDING_KIOSK_PATH,
  PAYMENT_KIOSK_PATH,
  TICKETS_PATH,
} from '../app/router/paths'
import { AppMode } from '../models/app'
import { appSlice } from '../store/appSlice'
import {
  BookingAvailability,
  bookingAvailabilitySlice,
} from '../store/bookingAvailabilitySlice'
import { licenseSlice } from '../store/licenseSlice'

import { useAppSelector } from './store'
import { useCurrentPath } from './useCurrentPath'

export function useBackLink(): string | null {
  const { currentPath } = useCurrentPath()
  const license = useAppSelector(licenseSlice.selectors.license)
  const appMode = useAppSelector(appSlice.selectors.mode)
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )

  if (!license) {
    return null
  }

  switch (currentPath) {
    case INSERT_PERIOD_PATH:
      if (appMode === AppMode.WEBSITE) {
        return `${license.beach.permalink}`
      }

      if (appMode === AppMode.KIOSK) {
        return `${generatePath(LANDING_KIOSK_PATH, { license: license.license })}`
      }

      return null
    case CHOOSE_PRODUCT_PATH:
      return `${generatePath(INSERT_PERIOD_PATH, { license: license.license })}`
    case TICKETS_PATH:
      if (!rd.isSuccess(bookingAvailability)) {
        return `${generatePath(INSERT_PERIOD_PATH, { license: license.license })}`
      }

      return bookingAvailability.value.type ===
        BookingAvailability.SPOTS_AND_TICKETS
        ? `${generatePath(CHOOSE_PRODUCT_PATH, { license: license.license })}`
        : `${generatePath(INSERT_PERIOD_PATH, { license: license.license })}`
    case PAYMENT_KIOSK_PATH:
      return `${generatePath(LANDING_KIOSK_PATH, { license: license.license })}`
    default:
      return null
  }
}
