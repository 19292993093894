export const Move = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2942 2.29289L9.1729 4.41421C8.78237 4.80474 8.78237 5.4379 9.1729 5.82843C9.56342 6.21895 10.1966 6.21895 10.5871 5.82843L11.0013 5.41421V9C11.0013 9.55228 11.449 10 12.0013 10C12.5536 10 13.0013 9.55228 13.0013 9V5.41421L13.4155 5.82843C13.8061 6.21895 14.4392 6.21895 14.8298 5.82843C15.2203 5.4379 15.2203 4.80474 14.8298 4.41421L12.7084 2.29289C12.3179 1.90237 11.6847 1.90237 11.2942 2.29289Z"
        fill="#5C5C66"
      />
      <path
        d="M2.29289 11.2929L4.41421 9.17156C4.80474 8.78103 5.4379 8.78103 5.82843 9.17156C6.21895 9.56208 6.21895 10.1952 5.82843 10.5858L5.41421 11H9C9.55228 11 10 11.4477 10 12C10 12.5523 9.55229 13 9 13H5.41421L5.82843 13.4142C6.21895 13.8047 6.21895 14.4379 5.82843 14.8284C5.4379 15.2189 4.80474 15.2189 4.41421 14.8284L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929Z"
        fill="#5C5C66"
      />
      <path
        d="M21.7071 11.2929L19.5858 9.17156C19.1953 8.78103 18.5621 8.78103 18.1716 9.17156C17.781 9.56208 17.781 10.1952 18.1716 10.5858L18.5858 11H15C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13H18.5858L18.1716 13.4142C17.781 13.8047 17.781 14.4379 18.1716 14.8284C18.5621 15.2189 19.1953 15.2189 19.5858 14.8284L21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929Z"
        fill="#5C5C66"
      />
      <path
        d="M9.1729 19.5858L11.2942 21.7071C11.6847 22.0976 12.3179 22.0976 12.7084 21.7071L14.8298 19.5858C15.2203 19.1953 15.2203 18.5621 14.8298 18.1716C14.4392 17.781 13.8061 17.781 13.4155 18.1716L13.0013 18.5858V15C13.0013 14.4477 12.5536 14 12.0013 14C11.449 14 11.0013 14.4477 11.0013 15V18.5858L10.5871 18.1716C10.1966 17.781 9.56342 17.781 9.1729 18.1716C8.78237 18.5621 8.78237 19.1953 9.1729 19.5858Z"
        fill="#5C5C66"
      />
    </svg>
  )
}
