import * as rd from '@devexperts/remote-data-ts'
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { MapElement, MapInitParam } from '@spiaggeit/map-canvas'

import { httpClient } from '../lib/http/HttpClient'
import { MapResponse } from '../models/map'
import { MAP_CELL_SIZE } from '../utils/constants'
import { fromISOtoTimestamp } from '../utils/dateUtils'
import { getFormattedMapElement } from '../utils/getFormattedMapElement'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'
import { insertPeriodSlice } from './insertPeriodSlice'
import { licenseSlice } from './licenseSlice'

export type MapSliceState = {
  mapData: rd.RemoteData<string, Partial<MapInitParam['opts']>>
  selectedElement: MapElement | null
}

const initialState: MapSliceState = {
  mapData: rd.initial,
  selectedElement: null,
}

export const loadMapData = createAsyncThunk<
  Partial<MapInitParam['opts']>,
  void,
  { rejectValue: string; state: RootState }
>('map/loadMapData', async (_, thunkApi) => {
  const state = thunkApi.getState()
  const period = insertPeriodSlice.selectors.period(state)
  const country = appSlice.selectors.country(state)
  const license = licenseSlice.selectors.license(state)

  if (!period || !period.end || !period.start) {
    return thunkApi.rejectWithValue('Period not defined')
  }

  const from = fromISOtoTimestamp(period.start)
  const to = fromISOtoTimestamp(period.end)

  try {
    const response = await httpClient.fetch<MapResponse>(
      `beaches/${license?.license}/map-v2?from=${from}&to=${to}`,
      country
    )

    if (response.status === 'success' && response.data) {
      const data = response.data.result
      return {
        background: data.background
          ? {
              height: data.background.height * MAP_CELL_SIZE,
              offset: {
                x: data.background.posX * MAP_CELL_SIZE,
                y: data.background.posY * MAP_CELL_SIZE,
              },
              url: data.background.url,
              width: data.background.width * MAP_CELL_SIZE,
            }
          : {
              height: 0,
              offset: {
                x: 0,
                y: 0,
              },
              url: null,
              width: 0,
            },
        elements: data.elements.map((element) =>
          getFormattedMapElement(element)
        ),
        size: {
          height: data.height,
          width: data.width,
        },
      }
    }

    throw new Error('Unexpected response structure')
  } catch (_error) {
    return thunkApi.rejectWithValue('Failed to fetch map data')
  }
})

export const mapSlice = createAppSlice({
  extraReducers: (builder) => {
    builder
      .addCase(loadMapData.pending, (state) => {
        state.mapData = rd.pending // Transition to loading state
      })
      .addCase(
        loadMapData.fulfilled,
        (state, action: PayloadAction<Partial<MapInitParam['opts']>>) => {
          state.mapData = rd.success(action.payload) // Transition to success state
        }
      )
      .addCase(loadMapData.rejected, (state, action) => {
        state.mapData = rd.failure(action.payload as string) // Transition to failure state
      })
  },
  initialState: initialState satisfies MapSliceState as MapSliceState,
  name: 'map',
  reducers: (create) => ({
    selectElement: create.reducer(
      (state, action: PayloadAction<MapElement | null>) => {
        state.selectedElement = action.payload
      }
    ),
  }),
})

export const mapSelectors = {
  error: (state: RootState): string | null =>
    rd.fold<string, Partial<MapInitParam['opts']>, string | null>(
      () => null,
      () => null,
      (e) => e,
      () => null
    )(state.map.mapData),
  hasError: (state: RootState) => rd.isFailure(state.map.mapData),
  isLoading: (state: RootState) => rd.isPending(state.map.mapData),
  mapData: (state: RootState) => {
    if (rd.isFailure(state.map.mapData)) {
      return null
    } else if (rd.isSuccess(state.map.mapData)) {
      return state.map.mapData
    }
  },
  selectedElement: (state: RootState) => state.map.selectedElement,
}
