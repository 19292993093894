import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { INSERT_PERIOD_PATH } from '../../app/router/paths'
import { Calendar } from '../../assets/icons/Calendar'
import { useAppSelector } from '../../hooks/store'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import { licenseSlice } from '../../store/licenseSlice'

export function InsertPeriodLink() {
  const license = useAppSelector(licenseSlice.selectors.license)
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const { i18n } = useTranslation()

  const { currentPath } = useCurrentPath()

  if (!license || !period || currentPath === INSERT_PERIOD_PATH) {
    return null
  }

  const startDateTime = DateTime.fromISO(period.start, {
    locale: i18n.language,
    zone: 'utc',
  })
  const endDateTime = DateTime.fromISO(period.end, {
    locale: i18n.language,
    zone: 'utc',
  })

  const dateFormat = 'dd MMM'
  const startDate = startDateTime.toFormat(dateFormat)
  const endDate =
    period?.end !== period?.start ? endDateTime.toFormat(dateFormat) : null
  const dateString = endDate ? `${startDate} - ${endDate}` : startDate

  return (
    <Link
      className="border-secondary flex flex-initial items-center space-x-2 rounded-sm border px-3 py-2 text-sm font-bold text-secondary"
      to={`${generatePath(INSERT_PERIOD_PATH, {
        license: license.license,
      })}`}
    >
      <Calendar className="h-5 w-5" />

      <span>{dateString}</span>
    </Link>
  )
}
