export const ArrowRight = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="currentColor"
    height={30}
    viewBox="0 0 20 20"
    width={30}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.9333 9.68333C14.8937 9.58103 14.8342 9.48758 14.7583 9.40833L10.5917 5.24166C10.514 5.16396 10.4217 5.10233 10.3202 5.06028C10.2187 5.01823 10.1099 4.99658 10 4.99658C9.77808 4.99658 9.56525 5.08474 9.40833 5.24166C9.33063 5.31936 9.269 5.4116 9.22695 5.51312C9.1849 5.61464 9.16326 5.72344 9.16326 5.83333C9.16326 6.05524 9.25141 6.26807 9.40833 6.42499L12.1583 9.16666H5.83333C5.61232 9.16666 5.40036 9.25446 5.24408 9.41074C5.0878 9.56702 5 9.77898 5 9.99999C5 10.221 5.0878 10.433 5.24408 10.5892C5.40036 10.7455 5.61232 10.8333 5.83333 10.8333H12.1583L9.40833 13.575C9.33023 13.6525 9.26823 13.7446 9.22592 13.8462C9.18362 13.9477 9.16183 14.0566 9.16183 14.1667C9.16183 14.2767 9.18362 14.3856 9.22592 14.4871C9.26823 14.5887 9.33023 14.6809 9.40833 14.7583C9.4858 14.8364 9.57797 14.8984 9.67952 14.9407C9.78107 14.983 9.88999 15.0048 10 15.0048C10.11 15.0048 10.2189 14.983 10.3205 14.9407C10.422 14.8984 10.5142 14.8364 10.5917 14.7583L14.7583 10.5917C14.8342 10.5124 14.8937 10.419 14.9333 10.3167C15.0167 10.1138 15.0167 9.88621 14.9333 9.68333Z" />
  </svg>
)
