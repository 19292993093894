import { PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import { AppMode, Country } from '../models/app'

import { createAppSlice } from './createAppSlice'

const COUNTRY_COOKIE = 'spiagge-country'

export type AppSliceState = {
  channel: string
  country: Country
  mode: AppMode
}

const initialState: AppSliceState = {
  // channel is a free text string, so it can be anything
  // it's used to track the source of the user
  channel: 'yb',
  country: Country.IT,
  // mode should be the kind of app the user is using
  mode: AppMode.WIDGET,
}

function getAppMode(url: URL): AppMode {
  const { host } = url

  if (host.startsWith('app.') || host.startsWith('new-app.')) {
    return AppMode.APP
  }

  if (host.startsWith('booking.') || host.startsWith('new-booking.')) {
    return AppMode.WEBSITE
  }

  if (host.startsWith('kiosk.') || host.startsWith('new-kiosk.')) {
    return AppMode.KIOSK
  }

  if (host.startsWith('widget.') || host.startsWith('new-widget.')) {
    return AppMode.WIDGET
  }

  return AppMode.WIDGET
}

export const appSlice = createAppSlice({
  initialState,
  name: 'app',
  reducers: (create) => ({
    setupByUrl: create.reducer((state, action: PayloadAction<string>) => {
      const url = new URL(action.payload)
      const searchParamChannel = url.searchParams.get('ch')
      const appMode = getAppMode(url)

      if (searchParamChannel === 'yb') {
        state.channel = 'yb'
        state.mode = AppMode.WEBSITE
      } else if (searchParamChannel === 'app-yb') {
        state.channel = 'app-yb'
        state.mode = AppMode.APP
      } else if (searchParamChannel === 'kiosk') {
        state.channel = 'kiosk'
        state.mode = AppMode.KIOSK
      } else if (!searchParamChannel) {
        if (appMode === AppMode.WEBSITE) {
          state.channel = 'yb'
          state.mode = AppMode.WEBSITE
        } else if (appMode === AppMode.WIDGET) {
          state.channel = 'widget'
          state.mode = AppMode.WIDGET
        } else if (appMode === AppMode.KIOSK) {
          state.channel = 'kiosk'
          state.mode = AppMode.KIOSK
        }
      } else {
        if (appMode === AppMode.WEBSITE) {
          state.channel = 'yb'
          state.mode = AppMode.WEBSITE
        } else {
          state.channel = searchParamChannel
          state.mode = appMode
        }
      }
      const country = url.searchParams.get('country')
      if (country !== null) {
        switch (country) {
          case 'fr':
            state.country = Country.FR
            break

          default:
            state.country = Country.IT
            break
        }
      } else {
        // check cookies
        const countryCookie = Cookies.get(COUNTRY_COOKIE)
        if (typeof countryCookie === 'string') {
          state.country = countryCookie as Country
        }
      }

      Cookies.set(COUNTRY_COOKIE, state.country)
    }),
  }),
  selectors: {
    channel: (state) => state.channel,
    country: (state) => state.country,
    mode: (state) => state.mode,
  },
})
