export const AvailableSpot = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" fill="#12B76A" r="10" />
    </svg>
  )
}
